<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header border-0">
        <h3 class="card-title">
          <span class="card-label font-weight-bolder text-dark">Loggers</span>
        </h3>
        <div
          v-if="
            currentUserRole === 'ROLE_ROOT' ||
            currentUserRole === 'ROLE_SUPER_ADMIN'
          "
          class="card-toolbar"
        >
          <!--begin::Dropdown-->
          <!--end::Dropdown-->

          <!--begin::Button-->
          <router-link
            to="/loggers/new"
            class="btn btn-primary font-weight-bolder"
          >
            <span class="svg-icon svg-icon-md">
              <inline-svg src="media/svg/icons/Media/Airplay.svg" /> </span
            >Add New Logger
          </router-link>
        </div>
      </div>
      <div class="card-body pt-0">
        <div class="row mb-5">
          <div class="col-md-4">
            <div class="form-group mb-0">
              <input
                @keyup="searchLoggers('imei')"
                v-model="imeiQuery"
                placeholder="Search by IMEI number"
                class="form-control form-control-solid form-control-lg"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-0">
              <input
                @keyup="searchLoggers('serialNumber')"
                v-model="serialNumberQuery"
                placeholder="Search by Serial number"
                class="form-control form-control-solid form-control-lg"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-0">
              <input
                @keyup="searchLoggers('msisdn')"
                v-model="msisdnQuery"
                placeholder="Search by MSISDN number"
                class="form-control form-control-solid form-control-lg"
              />
            </div>
          </div>
          <div class="col-md-2 mt-1"></div>
        </div>
        <div class="separator separator-dashed my-5"></div>
        <div class="row">
          <div class="col-md-12">
            <div v-if="isLoaded" class="table-responsive mb-0 pb-0">
              <table
                class="table table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr class="text-uppercase">
                    <th class="text-left pl-6">D</th>
                    <th style="min-width: 120px" class="pl-0">Sim card imei</th>
                    <th>Serial number</th>
                    <th>MSISDN</th>
                    <th
                      v-if="
                        currentUserRole === 'ROLE_ROOT' ||
                        currentUserRole === 'ROLE_SUPER_ADMIN' ||
                        currentUserRole === 'ROLE_ADMIN'
                      "
                      class="text-right pr-6"
                      style="min-width: 160px; text-align: right"
                    >
                      Options
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(logger, key) in loggers" :key="logger.id">
                    <td
                      class="text-left pl-6"
                      :class="{ 'border-top-0': key === 0 }"
                    >
                      <span
                        v-if="!logger.haveDevice"
                        class="label label-sm label-success p-3"
                      ></span>
                      <span
                        v-else
                        class="label label-sm label-danger p-3"
                      ></span>
                    </td>
                    <td class="pl-0" :class="{ 'border-top-0': key === 0 }">
                      <router-link
                        v-if="
                          currentUserRole === 'ROLE_ROOT' ||
                          currentUserRole === 'ROLE_SUPER_ADMIN' ||
                          currentUserRole === 'ROLE_ADMIN'
                        "
                        :to="'/loggers/edit/' + logger.id"
                        class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        >{{ logger.imei }}</router-link
                      >
                      <span
                        v-else
                        class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        >{{ logger.imei }}</span
                      >
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      {{ logger.serialNumber }}
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      {{ logger.msisdnNumber }}
                    </td>
                    <td
                      v-if="
                        currentUserRole === 'ROLE_ROOT' ||
                        currentUserRole === 'ROLE_SUPER_ADMIN' ||
                        currentUserRole === 'ROLE_ADMIN'
                      "
                      class="text-right pr-6"
                      :class="{ 'border-top-0': key === 0 }"
                    >
                      <button
                        @click="showModal(logger.company)"
                        class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg
                            src="media/svg/icons/Clothes/Briefcase.svg"
                          />
                        </span>
                      </button>
                      <router-link
                        :to="'/loggers/edit/' + logger.id"
                        class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg
                            src="media/svg/icons/General/Settings-1.svg"
                          />
                        </span>
                      </router-link>
                      <a
                        @click="deleteEntity(logger.id)"
                        href="#"
                        class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg src="media/svg/icons/General/Trash.svg" />
                        </span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="col-12 text-center pb-5">
              <b-spinner variant="primary" label="Spinning"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="logger-company-modal" centered hide-header hide-footer>
      <template>
        <div class="row">
          <b-button
            class="btn btn-light-primary mb-3"
            block
            @click="$bvModal.hide('logger-company-modal')"
            >Close</b-button
          >
          <div class="col-md-12">
            <h2>
              <strong>{{ company.name }}</strong>
            </h2>
            <strong>OIB:</strong> {{ company.oib }} <br />
            <strong>Adresa:</strong> {{ company.address }} <br />
            <strong>Email:</strong> {{ company.email }} <br />
            <strong>Korisnika:</strong> {{ company.users.length }} <br />
            <strong>Loggera:</strong> {{ company.loogers.length }} <br />
            <strong>Uredaja:</strong> {{ company.devices.length }} <br />
            <br />
            <router-link
              :to="'/companies/edit/' + company.id"
              class="btn btn-block btn-light-primary"
              >Pogledaj Detalje</router-link
            >
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import { mapGetters } from 'vuex'

export default {
  name: 'LoggersList',
  components: {},
  data() {
    return {
      loggers: [],
      loggersExport: [],
      companies: [],
      company: {
        id: null,
        name: null,
        oib: null,
        address: null,
        email: null,
        activeModules: [],
        devices: [],
        loogers: [],
        users: [],
      },
      imeiQuery: null,
      serialNumberQuery: null,
      msisdnQuery: null,
      timeout: null,
      debounceMilliseconds: 250,
      nameOrder: {
        query: 'asc',
        isActive: false,
      },
      isLoaded: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserRole', 'currentUserCompany']),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Loggers', route: '' }])

    if (
      this.currentUserRole === 'ROLE_ROOT' ||
      this.currentUserRole === 'ROLE_SUPER_ADMIN'
    ) {
      ApiService.get('loggers').then(({ data }) => {
        this.loggers = [...data['hydra:member']]
        this.isLoaded = true

        this.loggers.forEach((logger) => {
          this.loggersExport.push({
            imei: logger.imei,
            serialNumber: logger.serialNumber,
            msisdnNumber: logger.msisdnNumber,
          })
        })
      })
    }

    if (
      this.currentUserRole === 'ROLE_ADMIN' ||
      this.currentUserRole === 'ROLE_WORKER' ||
      this.currentUserRole === 'ROLE_JUNIOR'
    ) {
      ApiService.get(
        'loggers',
        `?company=${this.currentUserCompany.replace('/api/v1/companies/', '')}`
      ).then(({ data }) => {
        this.loggers = [...data['hydra:member']]
        this.isLoaded = true

        this.loggers.forEach((logger) => {
          this.loggersExport.push({
            imei: logger.imei,
            serialNumber: logger.serialNumber,
            msisdnNumber: logger.msisdnNumber,
          })
        })
      })
    }
  },
  methods: {
    searchLoggers(type) {
      clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        if (type === 'imei') {
          const loggerPromise = ApiService.get(
            'loggers',
            `?imei=${this.imeiQuery}`
          )

          Promise.all([loggerPromise]).then((values) => {
            this.loggers = []
            this.loggers = [...values[0].data['hydra:member']]
          })
        }

        if (type === 'serialNumber') {
          const loggerPromise = ApiService.get(
            'loggers',
            `?serialNumber=${this.serialNumberQuery}`
          )

          Promise.all([loggerPromise]).then((values) => {
            this.loggers = []
            this.loggers = [...values[0].data['hydra:member']]
          })
        }

        if (type === 'msisdn') {
          const loggerPromise = ApiService.get(
            'loggers',
            `?msisdnNumber=${this.msisdnQuery}`
          )

          Promise.all([loggerPromise]).then((values) => {
            this.loggers = []
            this.loggers = [...values[0].data['hydra:member']]
          })
        }
      }, this.debounceMilliseconds)
    },
    orderCompaniesByName() {
      this.nameOrder.isActive = true

      if (this.nameOrder.query === 'asc') this.nameOrder.query = 'desc'
      else this.nameOrder.query = 'asc'

      this.isCompaniesLoaded = false
      this.companies = []

      this.getCompanies(
        'companies',
        `?order[name]=${this.nameOrder.query}`
      ).then(({ data }) => {
        this.companies = [...data['hydra:member']]
        this.isCompaniesLoaded = true
      })
    },
    showModal(company) {
      ApiService.get(
        'companies',
        company.replace('/api/v1/companies/', '')
      ).then(({ data }) => {
        this.company.id = data.id
        this.company.name = data.name
        this.company.oib = data.oib
        this.company.address = data.address
        this.company.email = data.email
        this.company.devices = [...data.devices]
        this.company.loogers = [...data.loogers]
        this.company.users = [...data.users]

        this.$bvModal.show('logger-company-modal')
      })
    },
    deleteEntity(id) {
      let confirmation = confirm('Zelite izbrisati ovu Logger?')
      if (confirmation === false) return

      this.isLoaded = false
      ApiService.delete(`loggers/${id}`).then(() => {
        ApiService.get('loggers').then((response) => {
          this.loggers = [...response.data['hydra:member']]
          this.isLoaded = true
        })
      })
    },
  },
}
</script>

<style></style>
